<template>
    <v-container fluid pa-8 fill-height>
        <v-row>
            <v-col cols="12">
                <Promo
                    :promo.sync="currentPromo"
                    :previous="promo"
                    :readOnly="!promo.Approvata || !canModify"
                    :fakesync="fakesync"
                ></Promo>
            </v-col>
            <!-- <v-divider
                inset
                vertical
                class="mx-4"
                v-if="tobeApproved"
            ></v-divider> -->
            <!-- <v-col class="mx-2" v-if="tobeApproved">
                <Promo :promo="toApprove" :readOnly="true"></Promo>
            </v-col> -->
        </v-row>
        <v-container>
            <v-row>
                <v-col cols="12" class="d-flex">
                <v-spacer></v-spacer>
                <v-btn
                    class="mr-2"
                    v-if="!promo.Approvata && toApprove !== null"
                    @click="togglePrevious"
                    >
                    <v-icon left>
                        mdi-eye
                    </v-icon>
                    {{ toggleVersion }}
                    </v-btn>
                <v-btn
                    class="mr-2"
                    color="success"
                    v-if="!promo.Approvata"
                    @click="approved"
                    :disabled="!canApproveReject"
                    >APPROVA</v-btn
                >
                <v-btn
                    class="mr-2"
                    color="error"
                    v-if="!promo.Approvata"
                    @click="reject"
                    :disabled="!canApproveReject"
                    >RIFIUTA</v-btn
                >
                <v-btn
                    class="mr-2"
                    :disabled="!isChanged"
                    v-if="promo.Approvata"
                    @click="revert"
                    >ANNULLA</v-btn
                >
                <v-btn
                    color="primary"
                    class="ml-2"
                    :disabled="!isChanged || !canModify"
                    v-if="promo.Approvata"
                    @click="save"
                    >SALVA</v-btn
                >
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import * as promoActions from "../../store/actions/promo";
import * as farmPromosActions from "../../store/actions/farmPromos";
import * as storePromosActions from "../../store/actions/storePromos";

export const Promo = () => import("./Promo");

import { isEmptyObj } from "../../utils/utils";
import { checkObjectDiff } from "../../utils/utils";

export default {
    props: {
        promo: {
            type: Object,
            required: true
        },
        toApprove: {
            type: Object,
            required: false,
            default: null
        }
    },

    components: {
        Promo
    },

    data: () => ({
        currentPromo: null,
        resetModel: null,
        isChanged: false,
        fakesync: 0,
        showPrevious: false
    }),

    watch: {
        currentPromo: {
            handler: function(val) {
                this.isChanged = checkObjectDiff(
                    this.currentPromo,
                    this.resetModel
                );
            },
            deep: true,
            immediate: true
        }
    },

    computed: {
        ...mapState({
            canModify : state => state.userModule.privileges.addPromo,
            canApproveReject : state => state.userModule.privileges.approveReject
        }),

        tobeApproved() {
            return isEmptyObj(this.toApprove) ? false : true;
        },

        toggleVersion() {
            return this.showPrevious ? "Da approvare" : "Precedente";
        }
    },

    methods: {
        ...mapActions({
            updatePromo: promoActions.PROMO_UPDATEPROMO,
            approvePromo: promoActions.PROMO_APPROVEPROMO,
            rejectPromo: promoActions.PROMO_REJECTPROMO
        }),

        ...mapMutations({
            resetStorePromos : storePromosActions.STOREPROMOS_RESET,
            resetFarmPromos: farmPromosActions.FARMPROMOS_RESET
        }),

        togglePrevious() {
            this.showPrevious = !this.showPrevious;

            if (this.showPrevious) {
                this.resetModel = JSON.parse(JSON.stringify(this.promo));
                this.currentPromo = JSON.parse(JSON.stringify(this.promo));
                this.fakesync = new Date().getTime();
            } else {
                this.resetModel = JSON.parse(JSON.stringify(this.toApprove));
                this.currentPromo = JSON.parse(JSON.stringify(this.toApprove));
                this.fakesync = new Date().getTime();
            }
        },

        approved() {
            const msg = this.toApprove !== null ? "Confermi l'approvazione delle modifiche?" : "Confermi l'inserimento della comunicazione?"

            this.$confirmdialog
                .open({
                    type: "Warning",
                    title: "Approva Modifiche",
                    message: msg,
                    color: "primary"
                })
                .then(async response => {
                    try {
                        this.$confirmdialog.showprogress(true)
                        const status = await this.approvePromo({
                            promo: this.promo,
                            toApprove: this.toApprove,
                            farmId: this.$route.params.id
                        });
                        this.resetStorePromos()
                        this.resetFarmPromos()
                        this.$router.go(-1);
                        // console.log("ok gugol");
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    console.log("Annullato", err);
                })
                .finally(() => {
                    this.$confirmdialog.showprogress(false)
                    this.$confirmdialog.destroy();
                });
        },

        reject() {
            const msg = this.toApprove !== null ? 'Confermi il ripristino della comunicazione prima della modifica?' : 'Confermi la cancellazione della comunicazione?'
            this.$confirmdialog
                .open({
                    type: "Warning",
                    title: "Rifiuta Modifiche",
                    message: msg,
                    color: "primary"
                })
                .then(async response => {
                    try {
                        this.$confirmdialog.showprogress(true)
                        const status = await this.rejectPromo({
                            promo: this.promo,
                            toApprove: this.toApprove
                        });
                        console.log("ok gugol");
                        this.resetStorePromos()
                        this.resetFarmPromos()
                        this.$router.go(-1);
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    console.log("Annullato", err);
                })
                .finally(() => {
                    this.$confirmdialog.showprogress(false)
                    this.$confirmdialog.destroy();
                });
        },

        revert() {
            if (this.isChanged) {
                this.$confirmdialog
                    .open({
                        type: "Warning",
                        title: "Annulla Modifiche",
                        message: `Confermi l'annullamento delle modifiche?`,
                        color: "primary"
                    })
                    .then(async response => {
                        try {
                            this.$confirmdialog.showprogress(true)
                            this.currentPromo = JSON.parse(
                                JSON.stringify(this.resetModel)
                            );
                            this.fakesync = new Date().getTime();
                            this.resetStorePromos()
                            this.resetFarmPromos()
                            this.$router.go(-1);
                        } catch (err) {
                            console.error(err);
                        }
                    })
                    .catch(err => {
                        console.log("Annullato", err);
                    })
                    .finally(() => {
                        this.$confirmdialog.showprogress(false)
                        this.$confirmdialog.destroy();
                    });
            }
        },

        save() {
            if (this.isChanged) {
                console.log(this.$route);
                this.$confirmdialog
                    .open({
                        type: "Warning",
                        title: "Salva Modifiche",
                        message: `Confermi il salvataggio delle modifiche?`,
                        color: "primary"
                    })
                    .then(async response => {
                        try {
                            this.$confirmdialog.showprogress(true)
                            const status = await this.updatePromo(
                                this.currentPromo
                            );
                            console.log("ok gugol");
                            this.resetStorePromos()
                            this.resetFarmPromos()
                            this.$router.go(-1);
                        } catch (err) {
                            console.error(err);
                        }
                    })
                    .catch(err => {
                        console.log("Annullato", err);
                    })
                    .finally(() => {
                        this.$confirmdialog.showprogress(false)
                        this.$confirmdialog.destroy();
                    });
            }
        }
    },

    beforeMount() {
        if (this.promo.Approvata || this.toApprove === null) {
            this.resetModel = JSON.parse(JSON.stringify(this.promo));
            this.currentPromo = JSON.parse(JSON.stringify(this.promo));
        } else {
            this.resetModel = JSON.parse(JSON.stringify(this.toApprove));
            this.currentPromo = JSON.parse(JSON.stringify(this.toApprove));
        }
        console.log(this.promo, this.approve);
    },

    mounted() {}
};
</script>

<style>

</style>
